.verification-code-container {
    display: flex;
    justify-content: center; /* Centra los inputs horizontalmente */
    gap: 10px; /* Espaciado entre los inputs */
    margin-top: 20px; /* Espaciado superior */
}

.verification-input {
    margin-right: 10px; /* Añade un espacio de 10px entre los inputs */
    width: 50px; /* Ancho de cada input */
    height: 50px; /* Altura de cada input */
    text-align: center; /* Centra el texto dentro del input */
    font-size: 24px; /* Tamaño de la fuente */
    border: 2px solid #007bff; /* Borde azul */
    border-radius: 5px; /* Bordes redondeados */
    outline: none; /* Elimina el borde azul por defecto al enfocar */
    transition: border-color 0.3s ease; /* Transición suave para el borde */

    /* Sombra para un efecto de elevación */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.verification-input:focus {
    border-color: #28a745; /* Cambia el borde a verde al enfocar */
    box-shadow: 0 0 8px rgba(40, 167, 69, 0.5); /* Efecto de sombra verde al enfocar */
}

.dash {
    display: flex;
    align-items: center; /* Asegura que el guion esté centrado verticalmente */
    margin-right: 5px; /* Espacio entre el guion y el siguiente input */
    font-size: 18px; /* Tamaño del guion */
    font-weight: bold;
  }
